import React, { useState } from "react";
import { Modal,Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
function DeleteUser(props)
{
    const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL
    const {accessToken} = useAuth()
    const navigate = useNavigate()

    const [deleteModalShow, setDeleteModalShow] = useState(false);

    const handleClose = () => setDeleteModalShow(false);
    const handleShow = () => setDeleteModalShow(true);

    const [msg,setMsg] = useState(`Do you really want to Delete , ${props.name}'s data?`)
    
    function deleteUser()
    {
            fetch(`${BACKEND_API}/users/delete/`+props.id,{
                method:'DELETE',
                headers:{
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type':"application/json",
                    'Accept':"application/json"
                },
                                                            
            }).then(response =>{
                if(!response.ok){
                throw new Error("Failed");
                }
                return response.json();
            })
            .then(data =>{
            
                //console.log(data);
               if(data.success)
                {
                    //alert(data.message)
                    setMsg(`${props.name}'s, data is deleted!`)
                    setTimeout(()=>{
                        handleClose()
                    },2000)
                }
                else
                {
                 setMsg("User could not be deleted!")
                }

            }).catch(error=>{
                console.error('Login Error: ',error);
                navigate("/users/login")
            });
        }

    return <>
     <Button className="btn btn-warning"  style={{marginLeft:3}} onClick={handleShow}>Delete</Button>
    <Modal show={deleteModalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg} </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={deleteUser}>
                Yes
            </Button>
        </Modal.Footer>
      </Modal>
    </>
}

export default DeleteUser;
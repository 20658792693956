import React from "react";
import "./About.css"; // Ensure you have a CSS file for styling

function About() {
  return (
    <>
      <div className="about-container">
        {/* Hero Section */}
        <div className="hero-section">
          <h1 className="title">
            Welcome to <span>TechInfoAK Pvt Ltd</span>
          </h1>
        </div>

        {/* About Content */}
        <section className="about-content">
          <h2 className="section-title">Who We Are</h2>
          <p className="description">
            TechInfoAK Pvt Ltd is a leading provider of technology solutions dedicated to delivering exceptional value through innovation, personalized service, and continuous support. We serve a diverse range of industries with tailored solutions to meet unique needs.
          </p>
        </section>

        {/* Services Section */}
        <section className="services-section">
          <h2 className="section-title">Our Services</h2>
          <div className="services-list">
            <div className="service-item">
              <h3>Property Management System (PMS)</h3>
              <p>
                Streamline hotel operations and enhance guest experiences with our state-of-the-art Opera PMS solutions.
              </p>
            </div>
            <div className="service-item">
              <h3>EnviroSense Solutions</h3>
              <p>
                Advanced tools for environmental monitoring and management, ensuring sustainability and regulatory compliance.
              </p>
            </div>
            <div className="service-item">
              <h3>Website Development</h3>
              <p>
                Engaging and user-friendly websites that reflect your business's unique identity and objectives.
              </p>
            </div>
            <div className="service-item">
              <h3>Digital Marketing</h3>
              <p>
                Boost online visibility, attract customers, and achieve higher conversions with our cutting-edge digital marketing strategies and targeted email campaigns.
              </p>
            </div>
            <div className="service-item">
              <h3>Epson Printers</h3>
              <p>
                High-quality, reliable printing solutions as an authorized Epson distributor.
              </p>
            </div>
            <div className="service-item">
              <h3>Microsoft Products</h3>
              <p>
                Enhance collaboration and productivity with our suite of Microsoft tools and cloud solutions.
              </p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="contact-section">
          <h2 className="section-title">Get in Touch</h2>
          <p className="contact-text">
            Ready to take your business to the next level? Contact us today to learn how we can help your business thrive in the digital age.
          </p>
        </section>
      </div>
    </>
  );
}

export default About;



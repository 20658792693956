import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for managing user authentication
const AuthContext = createContext();

// Custom hook to consume the AuthContext
const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap your application and provide authentication context
function AuthProvider(props) 
{
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));

  useEffect(() => {
    // Check if user data exists in localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser)); // Set user data from localStorage
    }
    setAccessToken(localStorage.getItem('accessToken'));
   // console.log(user)
   // console.log(accessToken)
  }, []);

  const handleLogin = (userData,token) => {
    // Store user data in state and localStorage
    setUser(userData);
    setAccessToken(token)
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('accessToken', token);
  };

  const handleLogout = () => {
    // Perform logout actions (e.g., clear user data from state and storage)
    setUser(null);
    localStorage.removeItem('user'); // Remove user data from localStorage
    localStorage.removeItem('accessToken')
    setAccessToken("")
  };

  return (
    <AuthContext.Provider value={{ user, handleLogin, handleLogout, accessToken}}>
      {props.children}
    </AuthContext.Provider>
  );
};

export {AuthProvider,useAuth};
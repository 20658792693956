import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from "@fortawesome/free-solid-svg-icons";

function Products(props)
{
    return <>
    <section id="features" class="container">
    <div class="row">
      <div class="col-md-12 text-center section-header">
        <h2>Our Products and Services</h2>
        <p style={{justifyContent:"space-evenly"}}></p>
      </div>
      <div className="features">
     
        <div class="feature-box-2">
        <Link to="opera" style={{textDecoration:"none"}}>
            <div class="feature-box">
            <FontAwesomeIcon icon={fas.faHouse} className="feature-icon"/>
            <h2>Oracle Opera PMS</h2>
            <p>Oracle OPERA  Property Management Solutions streamline and enhance hotel operations</p>
            </div>
        </Link>

      </div>

      <div class="feature-box-2">
      <a href="http://envirosense.techinfoak.com" style={{textDecoration:"none"}}>
        <div class="feature-box"> 
          <FontAwesomeIcon icon={fas.faChartLine} className="feature-icon"/>
          <h2>EnviroSense</h2>
          <p>Specially designned Device for real-time Monitoring of Server Room temperature and Humidity, generating SMS and email alerts and providing reports.</p>
        </div>
        </a>

      </div>

        <div class="feature-box-2">
            <div class="feature-box"> 
                <FontAwesomeIcon icon={fas.faLaptopCode} className="feature-icon"/>
                <h2>Website Development</h2>
                <p>Fullstack Web solutions to meet your customised need escalating yopur business with online world</p>
            </div>
        </div>


        <div class="feature-box-2">
            <div class="feature-box"> 
                <FontAwesomeIcon icon={fas.faDesktop} className="feature-icon"/>
                <h2>Oracle Micros Workstations</h2>
                <p>The MICROS Workstation 4 LX/WS5a/Ws6 is a powerful and versatile point-of-sale system designed to meet the needs of businesses in the hospitality industry. </p>
            </div>
        </div>


        <div class="feature-box-2">
            <div class="feature-box"> 
                <FontAwesomeIcon icon={fas.faDesktop} className="feature-icon"/>
                <h2>Microsoft Suits</h2>
                <p>Unlocking Productivity: Enhance Workflows with Microsoft's Suite of Tools including Office Deck, Windows and many more.</p>
            </div>
        </div>


        <div class="feature-box-2">
        <Link to="epson" style={{textDecoration:"none"}}>
            <div class="feature-box"> 
                <FontAwesomeIcon icon={fas.faPrint} className="feature-icon"/>
                <h2>Epson Printers</h2>
                <p>Trusted for reliability, quality, and innovation. From compact inkjets to high-speed lasers, our range offers crisp prints with advanced features.</p>
            </div>
        </Link>
            
        </div>
  </div>
     
    </div>
  </section>
</>
}   

export default Products

import React, { useState } from "react";
import { Modal,Button,Row,Col,Form } from "react-bootstrap";
import {useNavigate } from 'react-router-dom';
import StatusBadge from "./StatusBadge";
import { useAuth } from "../../context/AuthContext";
const status=[
    "New","Active","Closed","Hold"
  ]


function CheckQuery(props)
{
    const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL
    const {accessToken} =useAuth()
    const navigate = useNavigate()

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [message,setMessage] = useState("")
    const [errors,setErrors] = useState([])

    const [query,setQuery]= useState(props.query)

    const [formData,setFormData] = useState(
        {
            'action':props.query.action,
            'status':props.query.status
        }
    )

    const handleChange=(e) =>{
        setFormData({...formData,[e.target.name]:e.target.value});
       // console.log(formData);
    };


    function handleSubmit(e){
        e.preventDefault();
       // console.log(formData);
    
        fetch(`${BACKEND_API}/queries/edit/`+props.query.id,{
            method:'POST',
            headers:{
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type':"application/json",
              'Accept':"application/json"
            },
            body:JSON.stringify(formData),
        })
      .then(response =>{
         // console.log(response)
          if(!response.ok){
            throw new Error("Failed");
          }
          return response.json();
      })
      .then(data =>{
         
         // console.log('Form submitted success',data);
  
        if(data.success){
          setErrors([])
          setMessage("Query Updated suucefully");
          
  
        //redirect users page here
         setTimeout(()=>{
            
             handleClose();
             props.onUpdate();
         },100)
          
        }
        else
        {
         // console.log(data.errors);
          setErrors(data.errors)
          setMessage(data.message +"! Please try again")
          
        }
        setFormData({
          action: '',
          status:'',  
      });
  })
  
    .catch(error=>{
     console.error('Error: ',error);
      //window.location.reload()
        navigate("/users/login")
       
    });
    }

    return <>
    <Button className="btn btn-primary"  style={{marginLeft:3}} onClick={handleShow}>Check</Button>
   
    <Modal show={show} onHide={handleClose} size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>Query Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col>
                    <label><strong>Name</strong></label>
                    <p>{query.name}</p>
                </Col>
                <Col>
                    <label><strong>Email</strong></label>
                    <p>{query.email}</p>
                </Col>
                <Col>
                    <label><strong>Mobile</strong></label>
                    <p>{query.mobile_number}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label><strong>Date</strong></label>
                    <p>{query.date}</p>
                </Col>
                <Col>
                    <label><strong>Status</strong></label>
                    <p><StatusBadge status= {query.status} /></p>
                </Col>
                <Col>
                    <label><strong>Product</strong></label>
                    <p>{query.product}</p>
                </Col>
                
            </Row>
            <Row>
                <Col sm={8}>
                    <label><strong>Query Statement</strong></label>
                    <p>{query.query}</p>
                </Col>
                
                <Col>
                    <label><strong>Action Taken</strong></label>
                    <p>{query.action}</p>
                </Col>
            </Row>
            <hr></hr>
            <Row>
            <Form className='form' onSubmit={handleSubmit}>
                <Row>
                    <Col sm={10}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Action</Form.Label>
                        <Form.Control type="text" name='action' value={formData.action} onChange={handleChange} />
                    </Form.Group>
                    </Col>
                    <Col sm={2}>
                        <Form.Group>
                            <Form.Label>
                            Status
                            </Form.Label>
                            <Form.Select aria-label="Default select example" name='status' value={formData.status} onChange={handleChange}>
                            {status.map((c)=>{
                                return <option value={c}>{c}</option>
                            })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                 
                </Row>
                <Button variant="success" type='submit' >Update</Button>
               


            </Form>
            </Row>
        
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            {/* <Button variant="primary" >
                Yes
            </Button> */}
        </Modal.Footer>
      </Modal>
    </>

}

export default CheckQuery;
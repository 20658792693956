import React from "react"
import { Alert } from "react-bootstrap"
function FormAlert(props)
{
    return <>
       
        {props.formResponse.message ==="" ? <></> : <>
        <Alert variant= {props.formResponse.success ? "success" :"danger" }>
            {props.formResponse.message}
            {Object.keys(props.formResponse.errors).length!==0?
            <ul>
                {Object.keys(props.formResponse.errors).map((e)=>{
                    return <li>{e} : {Object.values(props.formResponse.errors[e])[0]}</li>
                })}
            </ul>
            :<></>
            }
            </Alert>
        </>}
    
    </>
}

export default FormAlert;
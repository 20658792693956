import React,{useState} from "react"
import {Row,Col,Card,Form,Button} from "react-bootstrap"
import FormAlert from "../FormAlert";

var products = ["General",
   "Website Development","EnviroSense","Digital Marketing","Learning and Certification","Oracle Ws4lx","Oracle Ws5a","Oracle Ws6 Series","Posiflex","Epson","Cannon",
   "Oracle Opera PMS","Oracle Opera ORS","Oracle Opera Interfaces","Oracle Opera OXI","Oracle Opera OPI","Oracle Opera Cloud","Oracle POS 9700","Oracle POS 2700","Oracle POS 3700","Oralce POS Symphony","Oracle Material Control"
]

function AddQuery()
{
    const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL
    const[formData,setFormData]=useState({
        name: '',
        email:'',  
        mobile_number:'',
        product:'',
        query:'',
        status:'New'
      });

      

  const [formResponse,setFormResponse] = useState({
    success:false,
    message:"",
    errors:{}
  })
    
      const handleChange=(e) =>{
        setFormData({...formData,[e.target.name]:e.target.value});
        console.log(formData);
    };

      function handleSubmit(e){
            e.preventDefault();
            console.log(formData);
        
            setFormResponse(
                {
                  success:true,
                  message:"Sending please wait...",
                  errors:{}
                })

            fetch(`${BACKEND_API}/queries/add/`,{
                method:'POST',
                headers:{
                'Content-Type':"application/json",
                'Accept':"application/json"
                },
                body:JSON.stringify(formData),
            })
        .then(response =>{
            console.log(response)
            if(!response.ok){
                throw new Error("Failed");
            }
            return response.json();
        })
        .then(data =>{
            
            // console.log('Form submitted success',data);
    
            if(data.success)
            {
                setFormResponse({
                    success:true,
                    message:"Thanks! We will soon get back!",
                    errors:{}
                }) 
        
                //redirect users page here
                setTimeout(()=>{
                    setFormResponse({
                        success:false,
                        message:"",
                        errors:{}
                    }) 
                
                    setFormData({
                        name: '',
                        email:'',  
                        mobile:'',
                        product:'',
                        query:'',
                        status:'New'
                      });
                },2000)
                
                }
            else
            {
                console.log(data.errors);
                setFormResponse({
                    success:false,
                    message:data.message,
                    errors:data.error
                }) 
            }

    })
    
        .catch(error=>{
            console.error('Error: ',error); 
        });
    }


   return <Card >
            
        <Card.Body>
        <Card.Title className="text-center">Request a callback</Card.Title>
       <FormAlert formResponse={formResponse}/>
        <Form onSubmit={handleSubmit}>
        <Row>
            <Col md={6}>
                <Form.Group className="mb-3" controlId="name">
                <Form.Label>Your Name</Form.Label>
                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange}/>
            </Form.Group>
            </Col>

            <Col md={6}>
                <Form.Group className="mb-3" controlId="name">
                <Form.Label>Your Contact Number</Form.Label>
                <Form.Control type="number" name="mobile_number" value={formData.mobile_number} onChange={handleChange}/>
            </Form.Group>
            </Col>
        </Row>
        
        <Form.Group className="mb-3" controlId="email">
            <Form.Label>Your Email address</Form.Label>
            <Form.Control type="email"  name="email"  value={formData.email} onChange={handleChange} />
        </Form.Group>
       
        <Form.Group className="mb-3" controlId="product">
            <Form.Label>Product You interested in</Form.Label>
            <Form.Select aria-label="Default select example" name="product" value={formData.product} onChange={handleChange}>
            {products.map((p)=>{
                return <option value={p}>{p}</option>
            })}
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="message">
            <Form.Label>Query</Form.Label>
            <Form.Control as="textarea" rows={3} name="query" value={formData.query} onChange={handleChange}/>
        </Form.Group>
        <Button type="submit">Send</Button>
        </Form>
    </Card.Body>
    
    </Card>
}

export default AddQuery;
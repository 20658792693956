import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import {Card} from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Link } from 'react-router-dom';
import FormAlert from '../FormAlert';
import { useAuth } from '../../context/AuthContext';

const roles=[
  "admin","manager","customer"
]
function AddUser(){
  const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL
  const {accessToken} = useAuth()
  const navigate = useNavigate()
   
  const[formData,setFormData]=useState({
    name: '',
    email:'',  
    password:'',
    mobile_number:'',
    role:'admin',
    doj:'',

  });


  const [formResponse,setFormResponse] = useState({
    success:false,
    message:"",
    errors:{}
  })

  const handleChange=(e) =>{
      setFormData({...formData,[e.target.name]:e.target.value});
      //console.log(formData);
  };

  function handleSubmit(e){
      e.preventDefault();
     // console.log(formData);
     
      setFormResponse({
        success:true,
        message:"Adding user please wait...",
        errors:{}
    })
      fetch(`${BACKEND_API}/users/add/`,{
          method:'POST',
          headers:{
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type':"application/json",
            'Accept':"application/json"
          },
          body:JSON.stringify(formData),
      })
    .then(response =>{
        //console.log(response)
        if(!response.ok){
          throw new Error("Failed");
        }
        return response.json();
    })
    .then(data =>{
       
      //console.log('Form submitted success',data);

      if(data.success){
        setFormResponse({
            success:true,
            message:data.message,
            errors:{}
        })
      //redirect users page here
       setTimeout(()=>{
        navigate("/users");
       },2000)
        
      }
      else
      {
        //console.log(data.error);
        setFormResponse({
            success:false,
            message:data.message,
            errors:data.error
        })
      }
})

  .catch(error=>{
      console.error('Error: ',error); 
      navigate("/users/login")
  });

    }

    return <>
    <Row>
      <Col sm={2}></Col>
      <Col sm={8} style={{marginTop:50}}>
      <FormAlert formResponse={formResponse}/>
     
      <Card style={{backgroundColor:'#FAF0E6'}}>
      <Card.Header style={{backgroundColor:"#00BFFF" , fontFamily:'sans-serif'}}>Add New User</Card.Header>
      <Card.Body>
      
        <Form className='form' onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Name :
              </Form.Label>
              <Col sm="9">
                <Form.Control type="text" name='name' value={formData.name} onChange={handleChange} />
              </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" >
            <Form.Label column sm="3">
              Email :
            </Form.Label>
            <Col sm="9">
              <Form.Control type="email" name='email' value={formData.email} onChange={handleChange} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" >
            <Form.Label column sm="3">
              Password :
            </Form.Label>
            <Col sm="9">
              <Form.Control type="password" name='password' value={formData.password} onChange={handleChange} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" >
            <Form.Label column sm="3">
            Mobile :
            </Form.Label>
            <Col sm="9">
              <Form.Control type="number"  name='mobile_number' placeholder="Mob. No." value={formData.mobile_number} onChange={handleChange} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" >
            <Form.Label column sm="3">
            Role :
            </Form.Label>
            <Col sm="9">
            <Form.Select aria-label="Default select example" name='role' value={formData.role} onChange={handleChange}>
            {roles.map((c)=>{
                return <option value={c}>{c}</option>
              })}
            </Form.Select>
            </Col>
          </Form.Group>

      <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm="3">
        About
        </Form.Label>
        <Col sm="9">
          <Form.Control type="text"  name='about' value={formData.about} onChange={handleChange} />
        </Col>
      </Form.Group>
      <Button variant="success" type='submit' >Submit</Button>

      <Link to={"/users/"} className="btn btn-warning" style={{marginLeft:20}}>Users</Link>

      
      </Form>
      </Card.Body>
    </Card>
      </Col>
      <Col sm="2"></Col>
    </Row>
     
     
    </>
}

export default AddUser;
import React from "react"
import Container from 'react-bootstrap/Container';
import {Navbar,NavDropdown} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import TechinfoAKFinalLogo from "../img/TechinfoAKFinalLogo.jpg"
import { useAuth } from "../context/AuthContext";
function Navigation(props)
{
  const { user, handleLogout,accessToekn } = useAuth();
   
    return (
        <Navbar expand="lg"  data-bs-theme="light" >
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={TechinfoAKFinalLogo}
             
              height="50" 
              class="mr-4"
            />{' '}
           
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarNav" />
            <Navbar.Collapse id="navbarNav">             
                <Nav className="me-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <NavDropdown title="Services" id="basic-nav-dropdown">
                        <NavDropdown title="Hotel & Restaurent" id="basic-nav-dropdown" drop="end">
                            <NavDropdown.Item href="/opera">Opera PMS</NavDropdown.Item>
                            <NavDropdown.Item href="/opera">Opera ORS</NavDropdown.Item>
                            <NavDropdown.Item href="/opera">Opera Interfaces</NavDropdown.Item>
                            <NavDropdown.Item href="/opera">Opera OXI</NavDropdown.Item>
                            <NavDropdown.Item href="/opera">Opera OPI</NavDropdown.Item>
                            <NavDropdown.Item href="/opera">Opera Cloud</NavDropdown.Item>
                            <NavDropdown.Item href="/opera">Opera POS 9700</NavDropdown.Item>
                            <NavDropdown.Item href="/opera">Opera POS 2700</NavDropdown.Item>
                            <NavDropdown.Item href="/opera">Opera POS Symphony</NavDropdown.Item>
                            <NavDropdown.Item href="/opera">Opera Material Control</NavDropdown.Item>
                        </NavDropdown>

                          <NavDropdown title="Hardware" id="basic-nav-dropdown" drop="end">
                            <NavDropdown.Item href="/#get-in-touch">Oracle Ws4lx</NavDropdown.Item>
                            <NavDropdown.Item href="/#get-in-touch">Oracle Ws5a</NavDropdown.Item>
                            <NavDropdown.Item href="/#get-in-touch">Oracle Ws6 Series</NavDropdown.Item>
                            <NavDropdown.Item href="/#get-in-touch">Posiflex</NavDropdown.Item>
                            <NavDropdown.Item href="/epson">Epson</NavDropdown.Item>
                            <NavDropdown.Item href="/#get-in-touch">Cannon</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown.Item href="/#get-in-touch">Website Development</NavDropdown.Item>
                        <NavDropdown.Item href="/#get-in-touch">Digital Marketing</NavDropdown.Item>
                        <NavDropdown.Item href="/#get-in-touch">Learning & Certification</NavDropdown.Item>
                        <NavDropdown.Item href="/#get-in-touch">Posiflex</NavDropdown.Item>
                        <NavDropdown.Item href="/Epson">Epson</NavDropdown.Item>
                        <NavDropdown.Item href="/#get-in-touch">Cannon</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/#get-in-touch">Contact Us</Nav.Link>
                    
                </Nav>
                <Nav>
                  {user !==null && accessToekn !==""? <>
                  {user.role==='admin'?<><Nav.Link href="/users">Users</Nav.Link></>:""}
                    
                    <Nav.Link href="/queries">Queries</Nav.Link>
                    <NavDropdown title={user.name} >
                          <NavDropdown.Item onClick={handleLogout} href="/">Logout</NavDropdown.Item>
                      </NavDropdown>
                  </> : <>
                    <Nav.Link href="/users/login" className="btn btn-warning">Login</Nav.Link>
                  </>}               
                </Nav>
               
            </Navbar.Collapse>
        </Container>
      </Navbar>

    )
}

export default Navigation;
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Layout from './layouts/Layout';
import './App.css';
import Opera from './pages/Opera';
import Users from './pages/Users';
import Login from './pages/Login';
import ViewUser from './components/Users/ViewUser';
import Epson from './components/Epson'; 
import EditUser from './components/Users/EditUser';
import Queries from './pages/Queries';
import AddUser from './components/Users/AddUser';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/opera" element={<Opera />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/add" element={<AddUser />} />
        <Route path="/users/edit/:id" element={<EditUser />} />
        <Route path="/users/view/:id" element={<ViewUser />} />
        <Route path="/users/login" element={<Login />} />
        <Route path="/queries" element={<Queries />} />
        <Route path="/epson" element={<Epson />} /> {/* Added route for PrinterTypesPage */}
      </Routes>
    </Layout>
  );
}

export default App;


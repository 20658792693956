import React from "react"
import Footer from "../layouts/Footer";
import AddQuery from "./Queries/AddQuery";


function ContactUs()
{
    return <>   
   <section class="call-to-action" id="get-in-touch">
      <div class="container">
        <div class="row">

            <div class="col-md-6">
                <h2 class="mb-4">Get in Touch</h2>
                <p class="lead">Contact us to learn more about our solutions or for assistance.</p>
                <h4>Company Details</h4>
                <ul class="list-unstyled text-left">
                    <li><strong>TechInfo AK Pvt. Ltd.</strong></li>
                    <li>2nd Floor, Serenia, IHDP Business Park,</li>
                    <li>Sector 127, Noida</li>
                    <li>Email: info@techinfoak.com</li>
                    <li>Phone: +91 79069 15799</li>
                </ul>
            </div>
        <div class="col-md-6">
        {/* <Card >
            
            <Card.Body>
                <Card.Title className="text-center">Request a callback</Card.Title>
                <Form>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control type="text" name="name"/>
                    </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Your Contact Number</Form.Label>
                        <Form.Control type="number" name="contact" />
                    </Form.Group>
                    </Col>
                </Row>
                
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Your Email address</Form.Label>
                    <Form.Control type="email"  name="email"/>
                </Form.Group>
               
                <Form.Group className="mb-3" controlId="product">
                    <Form.Label>Product You interested in</Form.Label>
                    <Form.Select aria-label="Default select example">
                    {products.map((p)=>{
                        return <option value={p}>{p}</option>
                    })}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                    <Form.Label>Query</Form.Label>
                    <Form.Control as="textarea" rows={3} name="message"/>
                </Form.Group>
                <Button type="submit">Send</Button>
                </Form>
            </Card.Body>
            
            </Card> */}
           <AddQuery/>
        </div>
      </div>
      </div>
    </section>
    <Footer/>
    </>
}

export default ContactUs;
import {Table,Card,Col,Row,Container} from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import {useNavigate } from 'react-router-dom';
import CheckQuery from '../components/Queries/CheckQuery';
import DeleteQuery from '../components/Queries/DeleteQuery';
import StatusBadge from '../components/Queries/StatusBadge';
import { useAuth } from '../context/AuthContext';

function Queries(){
    const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL
    const {accessToken} = useAuth()
    const navigate = useNavigate()

    const [queries,setQueries] = useState([])

    const [reload,setReloald] = useState(0)

    function reloadTrigger()
    {
        setReloald(reload+1)
    }

    function getAllQueries()   
    {
        fetch(`${BACKEND_API}queries`,{
            method:'GET',
            headers:{
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type':"application/json",
                'Accept':"application/json"
            },
                                                        
        }).then(response =>{
            if(!response.ok){
              throw new Error("Failed");
            }
            //console.log(response)
            return response.json();
        })
        .then(data =>{
           
            //console.log(data);
            if(data.success)
            {
                setQueries(data.queries)
            }
            else
            {
                alert(data.message)
            }

        }).catch(error=>{
            console.error('Login Error: ',error);
            navigate('/users/login')
        });
    }

    useEffect(()=>{
         getAllQueries();
    },[reload])


    return<>
        <Row style={{paddingTop:30}}> 
            <Col sm={3}></Col>
            <Col sm={6} ><Card  style={{textAlign:'center', fontFamily:'sans-serif' ,backgroundColor:'#00BFFF'}} ><h2>Queries</h2> </Card></Col>
            <Col sm={3}></Col>
        </Row>
        
        <Container>
        <Row>
            <Col sm={1}></Col>
            <Col sm={10} style={{marginTop:"20px"}}>
              
                <Card>
                    {/* <Card.Header style={{backgroundColor:"#00BFFF"}}>Queries</Card.Header> */}
                    <Card.Body style={{backgroundColor:'#FAF0E6'}}>
                    <div className='table-wrapper'>

                    
                    <Table responsive="sm" style={{border:1}}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                           
                            <th>Mobile</th>
                            <th>Product</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            {queries.reverse().map((query,i)=>{

                                return <tr key={"user_"+i}>
                                    <td>{i+1}</td>
                                    <td>{query.name}</td>
                                    
                                    <td>{query.mobile_number}</td>
                                    <td>{query.product}</td>
                                    <td>{query.date}</td>
                                    <td><StatusBadge status={query.status}/> </td>
                                    <td>
                                        {/* <Link to={"/queries/view/"+query.id} className="btn btn-success">View</Link>
                                        <Link to={"/q/edit/"+query.id} className="btn btn-primary" style={{marginLeft:3}}>Edit</Link> */}
                                        <CheckQuery query={query} onUpdate={reloadTrigger}/>
                                        <DeleteQuery id={query.id} name={query.name} onDelete={reloadTrigger}/>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={1}></Col>
        </Row>
        </Container>
    </>
}
export default Queries;
import React from "react";
import "./PrinterTypesPage.css";
import { Link } from "react-router-dom";

const printerData = [
  // M-Series Printers
  {
    name: "EcoTank M1100",
    imgSrc: "/M1100_02_2-1.jpg",
    description: "Monochrome printer with high-efficiency performance, designed for low-cost and reliable printing needs, perfect for home offices and small businesses.",
  },
  {
    name: "EcoTank M100/M105",
    imgSrc: "/M100_550px.png",
    description: "Compact black-and-white printer offering exceptional printing quality with a focus on cost efficiency, ideal for small offices.",
  },
  {
    name: "EcoTank M200/M205",
    imgSrc: "/M200_550px.png",
    description: "All-in-one monochrome printer with scanning and copying functionality, perfect for productivity-focused environments.",
  },
  {
    name: "EcoTank M1120",
    imgSrc: "/m1120.jpg",
    description: "Wireless, cartridge-free printer designed for efficient and high-volume business printing with minimal maintenance.",
  },
  {
    name: "EcoTank M1140",
    imgSrc: "/M1140-2.jpg",
    description: "Advanced monochrome printer offering high-speed printing and exceptional durability for professional use.",
  },
  {
    name: "EcoTank M1170",
    imgSrc: "/Epson-EcoTank-M1170-Brochure-1-1024x768.jpg",
    description: "High-speed wireless printer with efficient ink tank technology and robust performance for business settings.",
  },
  {
    name: "EcoTank M1180",
    imgSrc: "/m1180.jpg",
    description: "Professional monochrome printer with wireless connectivity and durable construction for consistent performance.",
  },
  {
    name: "EcoTank M2110",
    imgSrc: "/m2110.jpg",
    description: "Cost-effective monochrome printer with superior print quality and energy efficiency.",
  },
  {
    name: "EcoTank M2120",
    imgSrc: "/m2120.jpg",
    description: "Multifunction monochrome printer offering scanning, copying, and reliable high-volume printing.",
  },
  {
    name: "EcoTank M2140/M2170",
    imgSrc: "/M2140_02_1-1.png",
    description: "Dual-function monochrome printer designed to enhance office productivity with automatic duplex printing.",
  },
  {
    name: "EcoTank M3140",
    imgSrc: "/m3140.webp",
    description: "Heavy-duty monochrome printer capable of handling industrial-level printing needs with precision.",
  },
  {
    name: "EcoTank M3170/M3180",
    imgSrc: "/M3170_690x460_2.jpg",
    description: "Smart monochrome printer with automatic duplex printing, wireless capabilities, and scanning features.",
  },
  {
    name: "EcoTank M15140",
    imgSrc: "/M15140 4.jpg",
    description: "A3-sized monochrome printer with high-volume capacity and professional-grade output for demanding tasks.",
  },

  // L-Series Printers
  {
    name: "EcoTank L130",
    imgSrc: "/l130.jpg",
    description: "Compact and affordable color printer ideal for home users and small offices, offering reliable performance.",
  },
  {
    name: "EcoTank L3210",
    imgSrc: "/l3210.jpg",
    description: "Multifunctional printer with capabilities for printing, scanning, and copying, perfect for home and office use.",
  },
  {
    name: "EcoTank L3215",
    imgSrc: "/l3215.webp",
    description: "Wireless printer with a high-volume ink tank system designed for cost-effective and efficient home office printing.",
  },
  {
    name: "EcoTank L3216",
    imgSrc: "/l3216.jpg",
    description: "A3-sized multifunction printer offering high-quality color printing for larger formats.",
  },
  {
    name: "EcoTank L3250",
    imgSrc: "/EPSON-L3250.jpg",
    description: "Compact printer with versatile functions including printing, scanning, and copying, suitable for home and small offices.",
  },
  {
    name: "EcoTank L3255",
    imgSrc: "/l3255.jpg",
    description: "User-friendly multifunctional printer with efficient ink usage, ideal for families and small businesses.",
  },
  {
    name: "EcoTank L3256",
    imgSrc: "/L3256-(2).jpg",
    description: "Wireless ink tank printer providing vibrant color output and excellent print durability.",
  },
  {
    name: "EcoTank L1250",
    imgSrc: "/L1250-(2).jpg",
    description: "Compact and efficient A3-sized printer delivering high-quality large-format prints.",
  },
  {
    name: "EcoTank L3260",
    imgSrc: "/l3260.jpg",
    description: "High-quality A3 color printer with a cost-effective ink system designed for creative professionals.",
  },
  {
    name: "EcoTank L4260",
    imgSrc: "/L4260-(2).jpg",
    description: "Feature-rich printer combining compact design with multifunctional capabilities for busy environments.",
  },
  {
    name: "EcoTank L5290",
    imgSrc: "/l5290.jpg",
    description: "Advanced multifunction printer offering fax, printing, scanning, and copying with excellent efficiency.",
  },
  {
    name: "EcoTank L6270",
    imgSrc: "/l6270.jpg",
    description: "Wireless printer with high-speed performance and robust ink tank system for large-scale projects.",
  },
  {
    name: "EcoTank L6460/L6490",
    imgSrc: "/L6490-(3).jpg",
    description: "Professional-grade A3 printer with superior output and seamless wireless integration for offices.",
  },
  {
    name: "EcoTank L6580",
    imgSrc: "/L6580-2.jpg",
    description: "Durable and efficient A3-sized printer designed for heavy-duty office tasks and high-quality printing.",
  },
  {
    name: "EcoTank L6570",
    imgSrc: "/L6570 -2.jpg",
    description: "Compact and powerful A3 printer offering precision and speed for demanding users.",
  },
  {
    name: "EcoTank L14150",
    imgSrc: "/l14150.jpg",
    description: "High-performance multifunction printer with fax and wireless capabilities for professional environments.",
  },
  {
    name: "EcoTank L15150",
    imgSrc: "/l15150.jpg",
    description: "A3 printer with advanced wireless features and a high-volume ink tank for extended use.",
  },
  {
    name: "EcoTank L15160",
    imgSrc: "/l15160.jpg",
    description: "Versatile multifunction printer offering A3-sized printing, scanning, and copying for large-scale tasks.",
  },
  {
    name: "EcoTank L15180",
    imgSrc: "/ecotank-l15180-a3-epson-original-imags9hrgx6kxvxq.webp",
    description: "Premium A3 wireless printer delivering outstanding print quality and exceptional durability.",
  },

  // Receipt Printers
  {
    name: "Epson TM-U220",
    imgSrc: "/TM-U220-AF-II-(1).png",
    description: "Reliable impact receipt printer ideal for retail and hospitality environments.",
  },
  {
    name: "Epson TM-U295",
    imgSrc: "/TM-U295-(550-x-310).png",
    description: "Compact slip printer designed for specialized printing tasks in banking and hospitality.",
  },
  {
    name: "Epson TM-T88VII",
    imgSrc: "/TM-T88VII_black-top-left-receipt_690x460.jpg",
    description: "High-performance thermal receipt printer with advanced features for fast and quiet operation.",
  },
  {
    name: "Epson TM-T88V",
    imgSrc: "/TM-T88V-(550-x-310).png",
    description: "Compact thermal printer offering fast and reliable receipt printing for point-of-sale systems.",
  },
  {
    name: "Epson TM-T88V-DT",
    imgSrc: "/pos_tm-t88v-dt-intelligent-receipt-printer-4_690x460.jpg",
    description: "Intelligent receipt printer with built-in computing capabilities for efficient retail operations.",
  },
  {
    name: "Epson T88VI",
    imgSrc: "/T88VI_Bk_Left_Paper - 550x310px.jpg",
    description: "Advanced thermal receipt printer with multiple connectivity options for flexible deployment.",
  },
  {
    name: "Epson TM-T88IV",
    imgSrc: "/pos_tm-t88iv-intelligent-receipt-printer_690x460.jpg",
    description: "Durable thermal receipt printer with high-speed performance for busy retail spaces.",
  },
  {
    name: "Epson TM-T83III",
    imgSrc: "/TM-T83III.png",
    description: "Cost-effective receipt printer offering fast and efficient printing for small businesses.",
  },
  {
    name: "Epson TM-T82X",
    imgSrc: "/TM-T82x.jpg",
    description: "Compact thermal printer designed for reliable receipt printing in retail and hospitality.",
  },
  {
    name: "Epson TM-T82III",
    imgSrc: "/TM-T82III.jpg",
    description: "Efficient thermal printer offering clear and fast receipt printing for point-of-sale needs.",
  },
  {
    name: "Epson TM-L100",
    imgSrc: "/Epson TM-L100.jpg",
    description: "Compact label printer with advanced features for retail and logistics applications.",
  },
  {
    name: "Epson TM-P20II",
    imgSrc: "/Epson TM-P20II.jpg",
    description: "Portable receipt printer offering fast and reliable performance for on-the-go transactions.",
  },
  {
    name: "Epson TM-P80",
    imgSrc: "/Epson TM-P80.png",
    description: "Lightweight and compact receipt printer ideal for mobile and retail use.",
  },
  {
    name: "Epson TM-T70II",
    imgSrc: "/Epson TM-T70II.webp",
    description: "Space-saving thermal receipt printer with front-loading capabilities for efficient operations.",
  },
  {
    name: "Epson TM-T82",
    imgSrc: "/Epson TM-T82.png",
    description: "Durable and reliable receipt printer designed for small to medium-sized businesses.",
  },
  {
    name: "Epson TM-T82II",
    imgSrc: "/Epson TM-T82II-i.jpg",
    description: "Compact and efficient thermal printer offering exceptional reliability and speed.",
  },
];

 

const Epson = () => {


  return (
    <div className="printer-types-container">
      {/* Main Title */}
      <h1 className="title">Epson EcoTank Printer</h1>
      <br />
      {/* M-Series Section Title */}
      <h2 className="subtitle">M-Series Printer</h2>

      {/* M-Series Printers Grid */}
      <div className="printer-grid">
        {printerData.slice(0, 13).map((printer, index) => (
          <div key={index} className="printer-card">
            <img
              src={printer.imgSrc}
              alt={printer.name}
              className="printer-image"
            />
            <p className="printer-name">{printer.name}</p>
            <p className="printer-description">{printer.description}</p>
            <Link to="/#get-in-touch" className="buy-now-button">
              Buy Now
            </Link>
            {/*<a className="buy-now-button"  href="https://techinfoak.com/#get-in-touch">
              Buy Now
            </a>*/}
          </div>
        ))}
      </div>

      {/* L-Series Section Title */}
      <h2 className="subtitle">L-Series Printer</h2>

      {/* L-Series Printers Grid */}
      <div className="printer-grid">
        {printerData.slice(13, 33).map((printer, index) => (
          <div key={index} className="printer-card">
            <img
              src={printer.imgSrc}
              alt={printer.name}
              className="printer-image"
            />
            <p className="printer-name">{printer.name}</p>
            <p className="printer-description">{printer.description}</p>
            <Link to="/#get-in-touch" className="buy-now-button">
              Buy Now
            </Link>
            {/*a className="buy-now-button" href="https://techinfoak.com/#get-in-touch">
            Buy Now
            </a>*/}
          </div>
        ))}
      </div>

      {/* Receipt Printers Section Title */}
      <h2 className="subtitle">Receipt Printers</h2>

      {/* Receipt Printers Grid */}
      <div className="printer-grid">
        {printerData.slice(33).map((printer, index) => (
          <div key={index} className="printer-card">
            <img
              src={printer.imgSrc}
              alt={printer.name}
              className="printer-image"
            />
            <p className="printer-name">{printer.name}</p>
            <p className="printer-description">{printer.description}</p>
            <Link to="/#get-in-touch" className="buy-now-button">
              Buy Now
            </Link>
            {/*<a className="buy-now-button" href="https://techinfoak.com/#get-in-touch">
              Buy Now
            </a>*/}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Epson;



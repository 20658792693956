import {Card,Col,Form,Row,Button} from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuth } from '../context/AuthContext';
import FormAlert from '../components/FormAlert';
function Login(props)
{
    const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL
    const { handleLogin } = useAuth();
    const[ formData,setFormData]=useState({
        email: '',
        password:''
    });

   
    const navigate = useNavigate()

    const handleChange=(e) =>{
        setFormData({...formData,[e.target.name]:e.target.value});
        //console.log(formData);
    };

    const [formResponse,setFormResponse] = useState({
      success:false,
      message:"",
      errors:{}
    })
    

    function handleSubmit(e){
        e.preventDefault();
          
        setFormResponse(
          {
            success:true,
            message:"Logging in please wait...",
            errors:{}
          })
          
        fetch(`${BACKEND_API}/users/login`,{
            method:'POST',
            headers:{
                'Content-Type':"application/json",
                'Accept':"application/json"
            },
            body:JSON.stringify(formData),
        })
    .then(response =>{
      //console.log(response)
        if(!response.ok){
            throw new Error('Login failed');
        }
        return response.json();
    })

    .then(data =>{

        /*
        sample data
        {
          "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImhpbWFuc2h1MUBnbWFpbC5jb20iLCJyb2xlIjoiY3VzdG9tZXIiLCJpYXQiOjE3MTI2NTE1NzZ9.QJ4E2WvqWKSpHfHqFI1e_zMbYhwhJgMAQq4dGxOXMks"
        }


        */
       // console.log('Login success',data);
        if(data.success)
        {
          setFormResponse(
            {
              success:true,
              message:"Login Success",
              errors:{}
            }
          )

          // now save accessToekn in local storage in order to access it in further reuquests to server
          //localStorage.setItem("accessToken",data.token)
          handleLogin(data.user,data.token)

          //redirect notes page here
          navigate("/queries");
        }
        else
        {
          setFormResponse(
            {
              success:false,
              message:"Login Failed",
              errors:{}
            }
          )
        }
    })

    .catch(error=>{
      setFormResponse(
        {
          success:false,
          message:"Login Failed. Something went wrong. Please try after sometime.",
          errors:{}
        })
        console.error('Login Error: ',error);
    });
    

    }
    return <>

    <Row>
      <Col sm={3}></Col>
      <Col sm={6} style={{marginTop:80}}>
      <FormAlert formResponse={formResponse}/>
      <Card >
      <Card.Header style={{backgroundColor:"#1E335B",color:"#FFF"}}>Login</Card.Header>
      <Card.Body>
        <Form className='form' onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="3">
        Your Email :
        </Form.Label>
        <Col sm="9">
          <Form.Control type="email" name="email" placeholder='email' onChange={handleChange} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm="3">
         Password :
        </Form.Label>
        <Col sm="9">
          <Form.Control type="password" name='password' placeholder="Password" onChange={handleChange} />
        </Col>
      </Form.Group>

  
      <Button variant="primary" type="submit" >Login</Button>
        </Form>
        

      </Card.Body>
    </Card>
      </Col>
      <Col sm={3}></Col>
    </Row>
     
    </>
}

export default Login;
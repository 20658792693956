import React from "react"
import {Badge} from "react-bootstrap"
const status ={
    'New':'danger',
    'Active':'info',
    'Closed':'success',
    'Hold':'warning'
}

function StatusBadge(props)
{
    return <>
        <h5>
        <Badge bg={status[props.status]}>{props.status}</Badge>
        </h5>
    </>
}

export default StatusBadge;